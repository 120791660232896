import {getLayoutData, getPageData} from "@/pages/api/api";
import { CONTENT_TYPES as COMMON_CONTENT_TYPES } from '@repo/utils/constants';
import { CONTENT_TYPES as DESIGN_CONTENT_TYPES } from "@/utils/constants";
import ExtendedContent from "@/components/common/ExtendedContent";

export default function Custom404({ data, locale }) {
    const contentData = data?.content || []
    return (
        <ExtendedContent content={contentData} />
    )
}

const CONTENT_TYPE = 'allDesignSinglePages'

const ERROR_PAGE_QUERY = `
	query Get404PageData($locale: SiteLocale, $slug: String) {
		${CONTENT_TYPE}(locale: $locale, filter: { slug: { eq: $slug } }) {
			singlePageName
			slug
			content {
                __typename
                ... on ${COMMON_CONTENT_TYPES.HEADER_DESCRIPTION_BUTTON_BANNER_RECORD} {
                    id
                    header
                    description
                    colorScheme {
                        colorScheme
                    }
                    buttonText
                }
                ... on ${COMMON_CONTENT_TYPES.CHECK_OTHERS} {
					id
					contactHeader
					checkOthersHeader
					isColorfulBackground
                    text {
                      text {
                        text
                        breaks {
                          image{
                            url
                          }
                        }
                      }
                    }
					links {
					  ... on ${DESIGN_CONTENT_TYPES.SINGLE_PAGE} {
						slug
						name
					  }
					}
			  	}
			}
		}
	}
`;

export async function getStaticProps(context) {
    const locale = context?.locale || 'pl';
    const data = await getPageData(CONTENT_TYPE, '404', ERROR_PAGE_QUERY, locale);
    const { globals, cookies, menu, footer } = await getLayoutData(locale)
    return {
        props: {
            data,
            globals,
            cookies,
            locale,
            menu,
            footer
        },
    };
}
